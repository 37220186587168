import { useState } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { styled as styleds } from "@mui/material/styles";
import Nav from "./Nav";
import Header from "components/core/Header";
import { Grid } from "@mui/material";
import styled from "styled-components";
import { breakpointsMedias } from "constanst/breakpointMedias";
//
// import Header from './header';
// import Nav from './nav';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styleds("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});
const StyleSidebar = styleds("div")({
  display: "flex",
  minWidth: "300px",
});

const Main = styleds("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 2,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  return (
    <StyledRoot>
      {/* <div >Header</div> */}
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main className="main-layout">
        <Outlet />
      </Main>
      {/* <StyleSidebar>

            </StyleSidebar> */}
      <Wrap className="shadow-md">
        <Grid minWidth={{ xl: 300 }}>
          <div></div>
        </Grid>
      </Wrap>
    </StyledRoot>
  );
}
const Wrap = styled.div`
  ${breakpointsMedias.min1400} {
    border-left: 1px dashed #454f5b;
    min-height: 100dvh;
  }
`;
