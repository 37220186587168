import { useLazyQuery } from "@apollo/client";
import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import { QUERY_GET_INFO_DEPOSIT } from "api/schema";
import NextStep from "components/core/ButtonStep";
import { ReloadContext } from "contexts/ReloadContext";
import { formatInput } from "helpers/format/formatInputAmount";
import { formatCustom, formatTrxToSun } from "helpers/format/formatNumber";
import useBalanceTrx from "helpers/hooks/tron/useBalanceTrx";
import { notifyToastify } from "helpers/toastNotify";
import { QRCodeSVG } from "qrcode.react";
import React, { useContext, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import styled from "styled-components";
import breakpoints from "theme/breakpoints";

const MIN_DEPOSIT = Number(process.env.REACT_APP_MIN_DEPOSIT) || 1;
const AccountDeposit = () => {
  const { isReload, onReload } = useContext(ReloadContext);
  const { balance } = useBalanceTrx();
  const [getInfoDeposit, { data, loading }] = useLazyQuery(
    QUERY_GET_INFO_DEPOSIT
  );
  const [amount, setAmount] = useState("");
  const [address, setAddress] = useState("");
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };
  useEffect(() => {
    if (data && data.rls_user_deposit_info_get) {
      const { deposit_address } = data.rls_user_deposit_info_get;
      setAddress(deposit_address);
    }
  }, [data]);
  const onDepositTRX = async () => {
    if (!address) {
      notifyToastify({
        type: "error",
        text: "Please reload fetch address first.",
      });
      return;
    }
    if (amount === "") {
      notifyToastify({ type: "error", text: "You must enter amount" });
      return;
    }
    if (formatInput(amount) < MIN_DEPOSIT) {
      notifyToastify({
        type: "error",
        text: `Amount must be bigger than ${MIN_DEPOSIT}TRX`,
      });
      return;
    }
    try {
      if (balance < formatInput(amount)) {
        notifyToastify({ type: "error", text: `Balance not Enought` });
        return;
      }
      await (window as any).tronWeb.trx.sendTransaction(
        address,
        formatTrxToSun(formatInput(amount))
      );
      notifyToastify({ type: "success", text: `Transaction deposit success!` });
      setTimeout(() => {
        onReload();
      }, 3000);
    } catch (error) {
      console.log("ERROR DEPOSIT TRX", error);
    }
  };
  useEffect(() => {
    getInfoDeposit({
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    });
  }, [isReload]);
  return (
    <Wrap>
      <Typography fontWeight={600}>Deposit TRX to account</Typography>
      <Box mt={3} />
      <Typography>Amount *</Typography>
      <Box mt={1} />
      {/* <TextField id="outlined-basic" variant="outlined"
                required
                fullWidth value={amount} name={"name"}
                onChange={onChange}
                type="number"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            /> */}
      <FormControl fullWidth>
        <NumericFormat
          customInput={TextField}
          value={amount}
          variant="outlined"
          thousandSeparator={true}
          onChange={onChange}
          autoComplete="off"
        />
      </FormControl>
      <Box mt={1} />
      <Typography fontSize={"0.75rem"} fontStyle={"italic"}>
        Balance: {formatCustom(balance, "0,0.0000")} TRX
      </Typography>
      <Box mt={2} />
      <div style={{ textAlign: "center" }}>
        {address && <QRCodeSVG value={address} includeMargin size={150} />}
      </div>
      <Box mt={2} />
      <NextStep size="full" text="Deposit" onClick={() => onDepositTRX()} />
    </Wrap>
  );
};

export default AccountDeposit;
const Wrap = styled.div`
  max-width: 250px;
  width: 100%;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
  @media (max-width: ${breakpoints.values.xl - 1}px) {
    position: static;
    top: 0;
    transform: translateY(0);
    padding: 30px 16px 0;
    max-width: 100%;
  }
`;
